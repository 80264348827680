.dragHandleIconContainer {
  display: flex;
  align-items: center;
  width: var(--spacing__unit--3);
}

.iconWrapper {
  line-height: 0;
  display: block;
}

.dragHandleIconContainer:hover {
  cursor: grab;
}
